// General layout. A slightly tighter grouping of the columns
// Standard is 15px
.col, .col-4, .col-8 {
    padding-left: 10px;
    padding-right: 10px;
}

// https://stackoverflow.com/questions/21852978/bootstrap-3-using-img-circle-how-to-get-circle-from-non-square-image
div.circle-avatar{
  /* make it responsive */
  max-width: 100%;
  width:100px;
  height:auto;
  display:block;
  /* div height to be the same as width*/
  padding-top:100px;

  /* make it a circle */
  border-radius:50%;

  /* Centering on image`s center*/
  background-position-y: center;
  background-position-x: center;
  background-repeat: no-repeat;

  /* it makes the clue thing, takes smaller dimension to fill div */
  background-size: cover;

  /* it is optional, for making this div centered in parent*/
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#donate-section {
  label {
    font-family: $font-family-alt;
  }
  div.additional {
    border-radius: 6px;

    padding: 30px;

    p {
      margin-top: 1.5rem;
      font-size: 1.2rem;
    }
  } 
  
  .bg-white {
    background: #ffffff;
  }

  .bg-light {
    background: #eaeff2 !important;
  }

  div.main {
      border-radius: 6px;
      padding: 30px;
  }
  
  img.icon-variant {
      display: none;
  }
  
  div.main div.row {
      margin-bottom: 8px;
  }
  
  .btn-secondary, .btn-secondary:hover {
      text-align: center;
      // margin: 0px;
      color: white !important;
  }

  .amount-active {
    background-color: #00a7e0 !important;
  }

  .amount-button {
    background-color: #97a1aa;
  }
  
  .form-control {
    font-size: $font-size-base;
  }

  // Additional pay methods
  // div.additional-pay-methods {
  //     margin-top: 1rem;
  //     margin-bottom: 1rem;
  //     padding: 1rem 2rem 0rem 2rem;
  //     border-radius: 0.3rem;
  //     color: #6d6d6d;
  
  //     background-color: #e8e7e7;
  //     span {
  //         white-space: nowrap;
  //     }
  // }
  
  $placeholder: #c8c0c0;
  .form-control::placeholder {
    color: $placeholder !important;
  }
  
}

.ElementsApp input {
  font-family: inherit !important;
}

