div.slide {
  position: relative;
  max-height: 450px;

  img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    object-fit: cover;
  }
}
div.caption {
  position: absolute;
  top: 0px;
  left: 50px;
  background-color: rgba(0,0,0,0.55);
  height: 100%;
  padding: 14% 3% 3% 3%;
  color: #1ABC9C;

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #fff;
  }

  p {
    font-size: 1rem;
    font-weight: 600;
    /* color: #fff; */
  }
}