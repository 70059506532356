// See https://css-tricks.com/snippets/css/simple-and-nice-blockquote-styling/
.quote-blockquote {
  // background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px 0 10px;
  quotes: "\201C""\201D""\2018""\2019";
  
  &::before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    vertical-align: -0.4em;
  }

  // First child flows on from the quote. Subsequent paragraphs are block.
  p:first-child {
    display: inline;
  }

}