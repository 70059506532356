/*------------------------
	icons
-------------------------*/
@function unicode($str) {
  @return unquote("\"\\#{$str}\"");
}

$nc-info-16: unicode(ea06);
$nc-s-info-e: unicode(ea07);
$nc-money-coins: unicode(ea17);
$nc-sun-fog-29: unicode(ea18);
$nc-pulse-chart: unicode(ea19);
$nc-analytics: unicode(ea1a);
$nc-ranking: unicode(ea1b);
$nc-s-pulse: unicode(ea1c);
$nc-c-pulse: unicode(ea1d);
$nc-board-30: unicode(ea1e);
$nc-chart-bar-32: unicode(ea1f);
$nc-g-chart: unicode(ea20);
$nc-d-chart: unicode(ea21);
$nc-chart-bar-33: unicode(ea22);
$nc-b-chart: unicode(ea23);
$nc-chart-pie-35: unicode(ea24);
$nc-chart-pie-36: unicode(ea25);
$nc-chart: unicode(ea26);
$nc-cogwheel: unicode(ea27);
$nc-configuration: unicode(ea28);
$nc-configuration-tool: unicode(ea29);
$nc-wrench-tool: unicode(ea2a);
$nc-wrench: unicode(ea2b);
$nc-settings: unicode(ea2c);
$nc-ruler-pencil: unicode(ea2d);
$nc-alien-33: unicode(ea2e);
$nc-single-05: unicode(ea2f);
$nc-single-03: unicode(ea30);
$nc-single-04: unicode(ea31);
$nc-single-02: unicode(ea32);
$nc-single-01: unicode(ea33);
$nc-network: unicode(ea34);
$nc-parent: unicode(ea35);
$nc-contacts: unicode(ea36);
$nc-layers-3: unicode(ea37);
$nc-simple-add: unicode(ea38);
$nc-simple-delete: unicode(ea39);
$nc-simple-remove: unicode(ea3a);
$nc-button-power: unicode(ea3b);
$nc-at-sign-2: unicode(ea3c);
$nc-mobile-chat: unicode(ea3d);
$nc-at-sign: unicode(ea3e);
$nc-send: unicode(ea3f);
$nc-reply: unicode(ea40);
$nc-reply-all: unicode(ea41);
$nc-newsletter: unicode(ea42);
$nc-newsletter-dev: unicode(ea43);
$nc-email: unicode(ea44);
$nc-letter: unicode(ea45);
$nc-file-edit: unicode(ea46);
$nc-attach: unicode(ea47);
$nc-attachment: unicode(ea48);
$nc-bulb-62: unicode(ea49);
$nc-bulb-61: unicode(ea4a);
$nc-bulb-63: unicode(ea4b);
$nc-lamp-2: unicode(ea4c);
$nc-light-3: unicode(ea4d);
$nc-light: unicode(ea4e);
$nc-vector: unicode(ea4f);
$nc-a-heart: unicode(ea50);
$nc-a-star: unicode(ea51);
$nc-bookmark-add: unicode(ea52);
$nc-bookmark-delete: unicode(ea53);
$nc-bookmark: unicode(ea54);
$nc-bookmark-add-2: unicode(ea55);
$nc-bookmark-delete-2: unicode(ea56);
$nc-favorite: unicode(ea57);
$nc-remove-favorite: unicode(ea58);
$nc-add-favorite: unicode(ea59);
$nc-add-like: unicode(ea5a);
$nc-remove-like: unicode(ea5b);
$nc-thumb-down: unicode(ea5c);
$nc-thumb-up: unicode(ea5d);
$nc-fav-remove: unicode(ea5e);
$nc-fav-list: unicode(ea5f);
$nc-star-rate: unicode(ea60);
$nc-s-info: unicode(ea61);
$nc-c-info: unicode(ea62);
$nc-info: unicode(ea63);
$nc-padlock-unlocked: unicode(ea64);
$nc-padlock: unicode(ea65);
$nc-ic_lock_outline_24px: unicode(ea66);
$nc-ic_lock_open_24px: unicode(ea67);
$nc-ic_lock_24px: unicode(ea68);
$nc-app: unicode(ea69);
$nc-a-edit: unicode(ea6a);
$nc-file-edit-2: unicode(ea6b);
$nc-folder-edit: unicode(ea6c);
$nc-m-edit: unicode(ea6d);
$nc-pen-01: unicode(ea6e);
$nc-pencil: unicode(ea6f);
$nc-file-2: unicode(ea70);
$nc-document: unicode(ea71);
$nc-file-article: unicode(ea72);
$nc-document-copy: unicode(ea73);
$nc-notepad: unicode(ea74);
$nc-notes: unicode(ea75);
$nc-sports-fan: unicode(ea76);
$nc-personal-trainer: unicode(ea77);
$nc-loan: unicode(ea78);
$nc-sports-fan-2: unicode(ea79);
$nc-personal-trainer-2: unicode(ea7a);
$nc-heart: unicode(ea7b);
$nc-hands-heart: unicode(ea7c);
$nc-cloud-upload-94: unicode(ea7d);
$nc-e-remove: unicode(ea7e);
$nc-pen-01-2: unicode(ea80);
$nc-bank: unicode(ea81);
$nc-bank-2: unicode(ea82);
$nc-moka: unicode(ea83);
$nc-logo-youtube: unicode(ea84);
$nc-logo-facebook: unicode(ea85);
$nc-logo-messenger: unicode(ea86);
$nc-logo-github: unicode(ea87);

.nc-info-16::before {
  content: $nc-info-16;
}

.nc-s-info-e::before {
  content: $nc-s-info-e;
}

.nc-money-coins::before {
  content: $nc-money-coins;
}

.nc-sun-fog-29::before {
  content: $nc-sun-fog-29;
}

.nc-pulse-chart::before {
  content: $nc-pulse-chart;
}

.nc-analytics::before {
  content: $nc-analytics;
}

.nc-ranking::before {
  content: $nc-ranking;
}

.nc-s-pulse::before {
  content: $nc-s-pulse;
}

.nc-c-pulse::before {
  content: $nc-c-pulse;
}

.nc-board-30::before {
  content: $nc-board-30;
}

.nc-chart-bar-32::before {
  content: $nc-chart-bar-32;
}

.nc-g-chart::before {
  content: $nc-g-chart;
}

.nc-d-chart::before {
  content: $nc-d-chart;
}

.nc-chart-bar-33::before {
  content: $nc-chart-bar-33;
}

.nc-b-chart::before {
  content: $nc-b-chart;
}

.nc-chart-pie-35::before {
  content: $nc-chart-pie-35;
}

.nc-chart-pie-36::before {
  content: $nc-chart-pie-36;
}

.nc-chart::before {
  content: $nc-chart;
}

.nc-cogwheel::before {
  content: $nc-cogwheel;
}

.nc-configuration::before {
  content: $nc-configuration;
}

.nc-configuration-tool::before {
  content: $nc-configuration-tool;
}

.nc-wrench-tool::before {
  content: $nc-wrench-tool;
}

.nc-wrench::before {
  content: $nc-wrench;
}

.nc-settings::before {
  content: $nc-settings;
}

.nc-ruler-pencil::before {
  content: $nc-ruler-pencil;
}

.nc-alien-33::before {
  content: $nc-alien-33;
}

.nc-single-05::before {
  content: $nc-single-05;
}

.nc-single-03::before {
  content: $nc-single-03;
}

.nc-single-04::before {
  content: $nc-single-04;
}

.nc-single-02::before {
  content: $nc-single-02;
}

.nc-single-01::before {
  content: $nc-single-01;
}

.nc-network::before {
  content: $nc-network;
}

.nc-parent::before {
  content: $nc-parent;
}

.nc-contacts::before {
  content: $nc-contacts;
}

.nc-layers-3::before {
  content: $nc-layers-3;
}

.nc-simple-add::before {
  content: $nc-simple-add;
}

.nc-simple-delete::before {
  content: $nc-simple-delete;
}

.nc-simple-remove::before {
  content: $nc-simple-remove;
}

.nc-button-power::before {
  content: $nc-button-power;
}

.nc-at-sign-2::before {
  content: $nc-at-sign-2;
}

.nc-mobile-chat::before {
  content: $nc-mobile-chat;
}

.nc-at-sign::before {
  content: $nc-at-sign;
}

.nc-send::before {
  content: $nc-send;
}

.nc-reply::before {
  content: $nc-reply;
}

.nc-reply-all::before {
  content: $nc-reply-all;
}

.nc-newsletter::before {
  content: $nc-newsletter;
}

.nc-newsletter-dev::before {
  content: $nc-newsletter-dev;
}

.nc-email::before {
  content: $nc-email;
}

.nc-letter::before {
  content: $nc-letter;
}

.nc-file-edit::before {
  content: $nc-file-edit;
}

.nc-attach::before {
  content: $nc-attach;
}

.nc-attachment::before {
  content: $nc-attachment;
}

.nc-bulb-62::before {
  content: $nc-bulb-62;
}

.nc-bulb-61::before {
  content: $nc-bulb-61;
}

.nc-bulb-63::before {
  content: $nc-bulb-63;
}

.nc-lamp-2::before {
  content: $nc-lamp-2;
}

.nc-light-3::before {
  content: $nc-light-3;
}

.nc-light::before {
  content: $nc-light;
}

.nc-vector::before {
  content: $nc-vector;
}

.nc-a-heart::before {
  content: $nc-a-heart;
}

.nc-a-star::before {
  content: $nc-a-star;
}

.nc-bookmark-add::before {
  content: $nc-bookmark-add;
}

.nc-bookmark-delete::before {
  content: $nc-bookmark-delete;
}

.nc-bookmark::before {
  content: $nc-bookmark;
}

.nc-bookmark-add-2::before {
  content: $nc-bookmark-add-2;
}

.nc-bookmark-delete-2::before {
  content: $nc-bookmark-delete-2;
}

.nc-favorite::before {
  content: $nc-favorite;
}

.nc-remove-favorite::before {
  content: $nc-remove-favorite;
}

.nc-add-favorite::before {
  content: $nc-add-favorite;
}

.nc-add-like::before {
  content: $nc-add-like;
}

.nc-remove-like::before {
  content: $nc-remove-like;
}

.nc-thumb-down::before {
  content: $nc-thumb-down;
}

.nc-thumb-up::before {
  content: $nc-thumb-up;
}

.nc-fav-remove::before {
  content: $nc-fav-remove;
}

.nc-fav-list::before {
  content: $nc-fav-list;
}

.nc-star-rate::before {
  content: $nc-star-rate;
}

.nc-s-info::before {
  content: $nc-s-info;
}

.nc-c-info::before {
  content: $nc-c-info;
}

.nc-info::before {
  content: $nc-info;
}

.nc-padlock-unlocked::before {
  content: $nc-padlock-unlocked;
}

.nc-padlock::before {
  content: $nc-padlock;
}

.nc-ic_lock_outline_24px::before {
  content: $nc-ic_lock_outline_24px;
}

.nc-ic_lock_open_24px::before {
  content: $nc-ic_lock_open_24px;
}

.nc-ic_lock_24px::before {
  content: $nc-ic_lock_24px;
}

.nc-app::before {
  content: $nc-app;
}

.nc-a-edit::before {
  content: $nc-a-edit;
}

.nc-file-edit-2::before {
  content: $nc-file-edit-2;
}

.nc-folder-edit::before {
  content: $nc-folder-edit;
}

.nc-m-edit::before {
  content: $nc-m-edit;
}

.nc-pen-01::before {
  content: $nc-pen-01;
}

.nc-pencil::before {
  content: $nc-pencil;
}

.nc-file-2::before {
  content: $nc-file-2;
}

.nc-document::before {
  content: $nc-document;
}

.nc-file-article::before {
  content: $nc-file-article;
}

.nc-document-copy::before {
  content: $nc-document-copy;
}

.nc-notepad::before {
  content: $nc-notepad;
}

.nc-notes::before {
  content: $nc-notes;
}

.nc-sports-fan::before {
  content: $nc-sports-fan;
}

.nc-personal-trainer::before {
  content: $nc-personal-trainer;
}

.nc-loan::before {
  content: $nc-loan;
}

.nc-sports-fan-2::before {
  content: $nc-sports-fan-2;
}

.nc-personal-trainer-2::before {
  content: $nc-personal-trainer-2;
}

.nc-heart::before {
  content: $nc-heart;
}

.nc-hands-heart::before {
  content: $nc-hands-heart;
}

.nc-cloud-upload-94::before {
  content: $nc-cloud-upload-94;
}

.nc-e-remove::before {
  content: $nc-e-remove;
}

.nc-pen-01-2::before {
  content: $nc-pen-01-2;
}

.nc-bank::before {
  content: $nc-bank;
}

.nc-bank-2::before {
  content: $nc-bank-2;
}

.nc-moka::before {
  content: $nc-moka;
}

.nc-logo-youtube::before {
  content: $nc-logo-youtube;
}

.nc-logo-facebook::before {
  content: $nc-logo-facebook;
}

.nc-logo-messenger::before {
  content: $nc-logo-messenger;
}

.nc-logo-github::before {
  content: $nc-logo-github;
}

