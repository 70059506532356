$primary-color: #1ABC9C;
$primary-hilight: #0c9d80;

a{
  color: $primary-color;

  &:hover{
    color: $primary-hilight;
  }
}
.navbar-engage .dropdown .dropdown-menu > li.active > a{
  color: $primary-color;
}
.navbar-engage .navbar-nav > li > a:hover,
.navbar-engage .navbar-nav > li.active > a,
.navbar-engage .navbar-nav > li.open > a,
.navbar-engage .navbar-nav > .active > a:hover,
.navbar-engage .navbar-nav > .active > a:focus,
.navbar-engage .navbar-nav > .open > a:hover{
  color: $primary-color;
}
.navbar-engage .navbar-nav > li a:hover::before,
.navbar-engage .navbar-nav > li.active a::before,
.navbar-engage .navbar-nav > li.open > a::before,
.navbar-engage .navbar-nav > .active > a:focus::before {
  background: $primary-color;
}
.navbar-engage .dropdown .dropdown-menu{
  border-color: $primary-color;
}
#main-menu > li > a:hover,
#main-menu > li.active > a,
#main-menu > li.open > a,
#main-menu > .active > a:hover,
#main-menu > .active > a:focus,
#main-menu > .open > a:hover{
  color: $primary-color;
}
#main-menu .dropdown .dropdown-menu > li.active > a{
  color: $primary-color;
}

.open>a{color: $primary-color !important;}

.navbar-engage .dropdown .dropdown-menu > li > a:hover, .navbar-engage .dropdown .dropdown-menu > li > a .active{
  color: $primary-color;
}
#main-menu .dropdown .dropdown-menu > li > a:hover,#main-menu .dropdown .dropdown-menu > li > a:active{
  color: $primary-color;
}

.section-title::before{
  border-color: $primary-color;
}
.small-title::before{
  border-color: $primary-color;
}
.page-header-section{background: $primary-color;}
#service-block-main .service-item:hover i{
  color: $primary-color;
  border-color: $primary-color;
}
#service-block-main .service-item:hover h2{
  color: $primary-color;
}
#service-block-main .service-item:hover .icon-wrapper::before {
  background: $primary-color;
}
#service-block-main .service-item:hover .icon-wrapper::after {
  background: $primary-color;
}
#portfolio .portfolio-items figure figcaption:hover{
  background: rgba(153, 204, 51, 0.9);
}
#other-services .other-service-item .icon .icon-medium{
  background: $primary-color;
}
#other-services .other-service-item:hover .icon-medium{
  background: #666;
}
#other-services .other-service-item .service-content h3{
  color: $primary-color;
}
#progress .icon-effect{
  color: $primary-color;
}
#clients #clients-scroller .owl-pagination .owl-page.active span{
  background: $primary-color;
}
#clients #clients-scroller .owl-pagination .owl-page span{
  border-color: $primary-color;
}
#testimonial #testimonial-carousel .carousel-control{
  color: $primary-color;
}
#testimonial #testimonial-carousel .carousel-control{
  color: $primary-color;
}
#testimonial .item blockquote small{
  color: $primary-color;
}
#testimonial .testimonial-inner:before{
  background: rgba(153, 204, 51, 0.2);
}
#blog .blog-item-img .blog-category{
  background: $primary-color;
}
#blog .blog-item-img .blog-category{
  background: $primary-color;
}
#blog .blog-item-text h3{
  color: $primary-color;
}
#blog .blog-item-text .blog-item-info a:hover{
  color: $primary-color;
}
#blog .blog-item-text h3 a:hover{
  color: $primary-color;
}

.scroll-top:hover{
  background: $primary-color;
  border-color: $primary-color;
}

/* About Us*/
.skill-wrapper .progress .progress-bar{
  background-color: $primary-color;
}
.skill-wrapper .progress .percent{
  color: $primary-color;
}
#why-choose .icon i{
  color: $primary-color;
}
#why-choose .tab-container h2{
  color: $primary-color;
}
#why-choose .tab-menu .list-group > a.active{
  background: $primary-color;
}
#notice .notice-content{
  border-color: $primary-color;
}
/* services */
#service-block-1 .service-item:hover .service-content h2{
  color: $primary-color;
}
#service-block-1 .service-item:hover .icon{
  border-color: $primary-color;
}
#service-block-1 .service-item:hover .icon i{
  background: $primary-color;
}
#service-block-2 .service-inner:hover i{
  color: $primary-color;
}
#service-block-2 .service-inner:hover h3{
  color: $primary-color;
}
#team-block-2 .team-member:hover{
  border-color: $primary-color;
}
.pricing-table-1 .table-header{
  background: $primary-color;
}
#pricing-block-2{
  background: $primary-color;
}
.pricing-table-x .plan .price{
  color: $primary-color;
}
.pricing-table-x .plan .period{
  color: rgba(153, 204, 51, 0.7);
}
.panel-default .panel-heading a{
  background: $primary-color;
}
/*Blog*/
#blog-page .sidebar .search-box .form-control:focus, #blog-page .sidebar .search-box button:focus, #blog-page .sidebar .search-box input:focus, #blog-page .sidebar .search-box textarea:focus{
  border-color: $primary-color;
}
#blog-page .sidebar .plain-search-btn{
  color: $primary-color;
}
#blog-page .post-content .reading::after{
  border-color: $primary-color;
}
.light li a{
  color: $primary-color;
}
#blog-page .widget-title::before{
  border-color: $primary-color  ;
}
.flat li a i{
  background: $primary-color;
  border-color: $primary-color;
}
#tabs .tabs-style-2 .nav-tabs li.active a, #tabs .tabs-style-2 .nav-tabs li.active a:hover, #tabs .tabs-style-2 .nav-tabs li.active a:focus, #tabs .tabs-style-2 .nav-tabs li a:hover{
  border-color: $primary-color;
  background-color: $primary-color;
}
#tabs .tabs-style-2 .nav-tabs{
  border-color: $primary-color;
}
#contact .contact-info-wrapper .contact-item-wrapper i{
  background: $primary-color;
}
.preset-bg{
  background: $primary-color;
}
.dark-bg{
  background: rgba(0, 0, 0, 0.1);
}
.btn-danger{
  background-color: $primary-color;
  border-color: $primary-color;
}
.btn-danger:hover,.btn-danger:active, .btn-danger.active, .open > .dropdown-toggle.btn-danger{
  background-color: #90BF34;
  border-color: #90BF34;
}
.page-header .page-header-inner:before{
  background-color: #90BF34 ;
  opacity: 0.2;
}
/*  Mobile menu */
.slicknav_btn{
  border-color: $primary-color;
}
.slicknav_btn:hover{
  background: $primary-color;
}
.slicknav_nav a:hover,
.slicknav_open .active a{
  color: $primary-color;
}
.slicknav_menu .slicknav_icon-bar{
  background: $primary-color;
}
.tparrows.preview2:hover:after{
  color: $primary-color;
}
#default-tab .nav-tabs > li > a.active , #default-tab .nav-tabs > li a.active:focus, #default-tab .nav-tabs > li a.active:hover {
    color: $primary-color;
}
#default-tab ul.nav-tabs > li a.active::before {
  background-color: $primary-color;
}

/* New Css */
#round-tab ul.nav-tabs {
  border-bottom: 1px solid $primary-color;
}

#round-tab ul li a.active {
    border-left: 1px solid $primary-color  ;
    border-right: 1px solid $primary-color  ;
    border-top: 1px solid $primary-color  ;
    color: $primary-color;
}
#vertical-tab ul.nav-tabs li a.active {
    border-bottom: 3px solid $primary-color  ;
}
#vertical-tab ul.nav-tabs li a.active > .fa {
  color: $primary-color;
}
#tab-with-icon .nav-tabs li a:hover i, #tab-with-icon .nav-tabs li a:focus, #tab-with-icon .nav-tabs li a.active i {
  background: $primary-color none repeat scroll 0 0;
}
.btn-outline {
  border-color: $primary-color;
  color: $primary-color;
}
.btn-outline:hover {
  background: $primary-color;
}
.progress-bar {
  background: $primary-color;
}
#testimonial .testimonial-footer {
  color: $primary-color;
}
#testimonial .owl-pagination .owl-page span {
  border: 1px solid $primary-color;
}
#testimonial .owl-pagination .owl-page.active span {
  background: $primary-color none repeat scroll 0 0;
}
#testimonial .testimonial-footer a {
    color: $primary-color;
}

#single-testimonial-item .testimonial-footer a {
    color: $primary-color;
}
#single-testimonial-item .owl-pagination .owl-page.active span {
  background: $primary-color none repeat scroll 0 0;
}
#single-testimonial-item .owl-pagination .owl-page span {
  border: 1px solid $primary-color;
}
#color-client-scroller .client-item-wrapper {
  background: $primary-color none repeat scroll 0 0;
}
#clients .client-item-wrapper:hover {
    border-color: $primary-color;
}
i.icon-default {
  color: $primary-color;
}
i.icon-round {
  background-color: $primary-color;
}
i.icon-round-border {
  border: 3px solid $primary-color;
  color: $primary-color;
}
i.icon-box {
  background-color: $primary-color;
}
i.icon-box-border {
  border: 3px solid $primary-color;
  color: $primary-color;
}
.blog-post-footer ul li a {
  border: 1px solid $primary-color;
  color: $primary-color;
}
.blog-post-footer ul li a:hover {
  background: $primary-color none repeat scroll 0 0;
  border: 1px solid $primary-color;
}
.blog-post-content blockquote.blockquote {
    border-left: 5px solid $primary-color;
}
.blog-pagination nav ul.pagination li.page-item.active a , .blog-pagination nav ul.pagination li.page-item a:hover , .blog-pagination nav ul.pagination li.page-item a:focus {
    background: $primary-color none repeat scroll 0 0;
    border: 1px solid $primary-color;
}
.form-control:focus {
  border-color: $primary-color  ;
}
h2.widget-title {
  color: $primary-color;
}
.widget.tag-cloud a {
  background: $primary-color;
}
.sidebar-area .search-bar button i.fa {
  color: $primary-color;
}
.contact-title-text h2 {
  color: $primary-color;
}
.roof-menu ul li.active a {
    color: $primary-color;
}
.roof-social-icons a i.fa:hover {
  background: $primary-color;
}
.service2-single-service .fa {
    background: $primary-color none repeat scroll 0 0;
}
.form-control:focus {
  border-color: $primary-color  ;
}
.btn-common {
  background: $primary-color none repeat scroll 0 0;
}
.btn-common::before {
  background: #2e8271 none repeat scroll 0 0;
}
.back-to-top i {
  background-color: $primary-color;
}
#carousel-area .carousel-item .carousel-caption {
  color: $primary-color;
}
.top_search .top_search_icon {
  color: $primary-color;
}
#carousel-area .carousel-indicators li:hover, #carousel-area .carousel-indicators li.active {
  background: $primary-color none repeat scroll 0 0;
}
.service-item .icon-wrapper i {
  color: $primary-color;
}
.split .details-list h3 {
  color: $primary-color;
}
#other-services .nav-tabs li a:hover i, #other-services .nav-tabs li a:focus, #other-services .nav-tabs li a.active i {
  background: $primary-color none repeat scroll 0 0;
}
.btn-common.active {
  background: #2e8271 none repeat scroll 0 0;
}
.sup-desc-wrap .sup-link .left-link, .sup-desc-wrap .sup-link .right-link {
  background: $primary-color none repeat scroll 0 0;
  border: 1px solid $primary-color;
}
.featured-box .featured-icon i {
  color: $primary-color;
}
#team .team-item figure figcaption .orange-line {
  background: $primary-color none repeat scroll 0 0;
}
#team .team-item .social .facebook, #team .team-item .social .twitter, #team .team-item .social .google-plus {
  background: #19BC9C none repeat scroll 0 0;
  border: 1px solid $primary-color;
}
.orange-line {
  background: $primary-color none repeat scroll 0 0;
}
#cool-facts .facts-item i {
  color: $primary-color;
}
#testimonial .testimonial-inner:hover {
  border-color: $primary-color;
}
#blog .blog-item-text .blog-one-footer i {
  color: $primary-color;
}
#blog .blog-item-text .blog-one-footer a:hover {
  color: $primary-color !important;
}
.portfolio-meta ul li span {
  color: $primary-color;
}
#main-menu ul .dropdown .dropdown-menu .dropdown-item.active, #main-menu ul .dropdown .dropdown-menu .dropdown-item.active:focus, #main-menu ul .dropdown .dropdown-menu .dropdown-item.active:hover {
  color: $primary-color  ;
  background-color: #eee;
}
#main-menu .nav-link.active {
  color: $primary-color;
}
#main-menu .nav-link:hover {
  color: $primary-color;
}
.sup-desc-wrap .sup-link a:hover {
  color: $primary-color;
}
#team .team-item .social a:hover {
  color: $primary-color;
}
#main-menu ul .dropdown .dropdown-menu a:hover, #main-menu .dropdown .dropdown-menu a .active {
  color: $primary-color;
}
/* Hosting */
.success {
  // color: $primary-color;
  color: $primary-color;
}
.fail {
  color: red;
}