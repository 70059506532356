/*You can add your won color also */
body {
  font-family: 'Open Sans', sans-serif;
  background: #fff;
  font-size: 1rem;
  font-weight: 400;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #666;
  line-height: 25px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.form-group .help-block {
  color: red;
}
.roof-menu ul li.active a:hover {
  opacity: 0.8;
}
.roof-menu ul li a:hover {
  opacity: 0.8;
}
.section-padding {
  padding: 110px 0;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-80 {
  margin-top: 80px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50pxs;
}
h1,
h2,
h3,
h4 {
  font-family: 'Muli', sans-serif;
  font-weight: 400;
}
p {
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}
a {
  
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
a:hover {
  color: #a22f30;
  text-decoration: none;
}
a:focus {
  outline: none;
  text-decoration: none;
}
.ordinary-row {
  padding: 80px 0 90px;
}
.section-title + .row {
  padding: 40px 0 90px;
}
.btn,
button {
  padding: 7px 30px;
  border-radius: 0px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.section {
  padding: 40px 0;
}
section#service-block-home {
  padding: 80px 0;
}
.input-group-btn button {
  padding: 11px 20px;
}
.btn-common {
  color: #FFF;
  background: #ff5959;
  position: relative;
  border: transparent;
  z-index: 1;
}

.btn-common:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  z-index: -1;
  
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.btn-radius:before {
  border-radius: 5px 0px 0px 5px; 
}
.btn-round {
  border-radius: 1.5em;
}
.btn-outline {
 
  border-width: 2px;
}
.btn-outline:hover {
  color: #fff;
  border: 2px solid transparent;
  
}
.btn-common:hover:before {
  width: 100%;
}
.btn-border {
  background: rgba(255, 255, 255, 0.18);
  border: 2px solid #fff;
  color: #fff;
}
.btn-border:hover {
  background: rgba(255, 255, 255, 0.3);
  border: 2px solid #ffffff;
  color: #fff;
}
.btn-lg {
  padding: 14px 33px;
  text-transform: uppercase;
  font-size: 16px;
}
.btn-xs {
  font-size: 11px;
  padding: 10px 12px;
}
.btn-sm {
  padding: 12px 15px;
}
.btn-md {
  font-size: 15px;
  padding: 12px 25px;
}
.btn-radius {
  border-radius: 5px;
}

.input-group .form-control {
  border-radius: 30px 0 0 30px;
}
.form-group label {
  margin-bottom: 24px;
}
.form-group .radius-input {
  border-radius: 30px;
  height: 45px;
}
.form-group .flat-input {
  border-radius: 0;
}
.form-group .form-control {
  background: #ffffff;
  padding-right: 30px;
  color: #666;
  position: relative;
  box-shadow: none;
}
.form-group .form-control:focus {
  
}
.form-group .help-block {
  font-weight: 700;
}
.label {
  font-weight: normal;
  margin: 0 2px;
}
.social-links {
  display: inline-block;
}
.social-links a {
  display: inline-block;
  margin: 0 0 0 5px;
}
.social-links a i {
  width: 32px;
  height: 32px;
  vertical-align: middle;
  border-radius: 100%;
  line-height: 32px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.social-links a i:hover {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.social-links a .fa-twitter:hover {
  color: #00aced;
}
.social-links a .fa-facebook:hover {
  color: #3b5998;
}
.social-links a .fa-google-plus:hover {
  color: #dd4b39;
}
.social-links a .fa-youtube-play:hover {
  color: #bb0000;
}
.social-links a .fa-linkedin:hover {
  color: #007bb6;
}
li.search {
  position: inherit;
  padding-right: 10px;
}
li.search .open-search {
  margin-right: 0 !important;
}
li.search .open-search i {
  font-size: 13px;
}
h1.top {
  background: #1ABC9C;
  color: white;
  padding: .4rem;
  text-align: center;
}
// .navbar-engage .navbar-nav > li.search > a:before {
//   display: none;
// }
i {
  transition: all 0.3s ease-in-out;
}
i:hover {
  opacity: 0.8;
  transition: all 0.3s ease-in-out;
}
.full-search {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #333;
  padding: 15px 0;
  z-index: 999;
}
.full-search input[type="text"] {
  outline: medium none;
  display: inline-block;
  width: 97%;
  background: transparent;
  color: #FFF;
  font-size: 28px;
  padding: 4px 16px 8px;
  line-height: 39px;
  border-radius: 3px;
  height: auto;
  border: 1px solid #444;
}
.section-title {
  text-align: center;
  font-size: 38px;
  line-height: 40px;
  color: #000;
  font-family: 'Muli', sans-serif;
  padding: 10px 0;
}
.section-subcontent {
  text-align: center;
  padding-bottom: 30px;
}
.small-title {
  font-size: 22px;
  position: relative;
  text-align: left;
}

/* ScrollToTop */
.back-to-top {
  display: none;
  position: fixed;
  bottom: 18px;
  right: 15px;
}
.back-to-top i {
  display: block;
  font-size: 18px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  color: #fff;
  border-radius: 0px;
  text-align: center;
  
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.page-header {
  padding: 0;
  margin: 0;
  position: relative;
  background: url(/static/img/backgrounds/testimonial-bg.jpg);
  min-height: 164px;
}
.page-header .page-header-inner {
  color: #fff;
  height: 100%;
  padding: 40px 0;
  position: absolute;
  width: 100%;
}
.page-header .page-header-inner:before {
  background: rgba(231, 76, 60, 0.2);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  content: "";
  display: block;
}
.page-header .page-title {
  color: #fff;
  font-size: 44px;
  font-weight: 600;
  margin-bottom: 10px !important;
  padding: 15px 0;
  text-align: left;
}
.page-header .breadcrumb {
  margin: 0;
  padding: 0;
  background: transparent;
  position: relative;
  z-index: 9;
}
.page-header .breadcrumb li {
  line-height: 25px;
}
.page-header .section-title:before {
  width: 40px;
}
.switcher-box {
  position: fixed;
  width: 140px;
  top: 20%;
  left: -140px;
  z-index: 9999;
  background: #FFF;
  border: 1px solid #F3F3F3;
  padding: 15px;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -ms-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.switcher-box h4 {
  color: #292929;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  margin-top: 0px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-weight: bold;
}
.switcher-box .colors-list {
  margin: 0px 0px 0px -4px;
}
.switcher-box .colors-list li {
  display: inline-block;
  font-size: 0px;
  list-style: none;
  margin: 0px 4px;
}
.switcher-box .colors-list li a {
  width: 25px;
  cursor: pointer;
  height: 25px;
  display: block;
}
.switcher-box .colors-list li a.default {
  background-color: #9C3;
}
.switcher-box .colors-list li a.red {
  background-color: #c74041;
}
.switcher-box .colors-list li a.lightblue {
  background-color: #00D3F9;
}
.switcher-box .colors-list li a.gray {
  background-color: #9BB5AC;
}
.switcher-box .colors-list li a.coffee {
  background-color: #998675;
}
.switcher-box .colors-list li a.green {
  background-color: #9C3;
}
.switcher-box .colors-list li a.orange {
  background-color: #F60;
}
.switcher-box .colors-list li a.wisteria {
  background-color: #9b59b6;
}
.switcher-box .colors-list li a.mblue {
  background-color: #34495e;
}
.switcher-box .colors-list li a.greensea {
  background-color: #1abc9c;
}
.switcher-box .open-switcher {
  position: absolute;
  right: -50px;
  top: -1px;
  width: 50px;
  border-radius: 0px;
  border: 0px none;
  padding: 0px;
  height: 50px;
  background: #DDD;
}
.switcher-box .open-switcher i {
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  font-size: 25px;
  color: #292929;
  display: block;
}
.switcher-massage {
  font-size: 12px;
  line-height: 15px;
  color: 808080;
  margin: 10px 0px 0px;
}
#loader {
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999999;
}
.square-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -40px;
  margin-top: -40px;
}
.square-spin img {
  max-width: 64px;
}
#carousel-area {
  overflow: hidden;
  background: #2c3e50;
}
#carousel-area .carousel-inner .carousel-item {
  opacity: 0;
  transition-property: opacity;
}
#carousel-area .carousel-inner .active {
  opacity: 1;
}
#carousel-area .carousel-inner .active.left,
#carousel-area .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}
#carousel-area .carousel-inner .next.left,
#carousel-area .carousel-inner .prev.right {
  opacity: 1;
}
#carousel-area .carousel-control {
  z-index: 2;
}
#carousel-area .carousel-item {
  background-size: 100%;
  background-size: cover;
  height: 540px;
}
#carousel-area .carousel-item .carousel-caption {
  
  top: 22%;
  right: 10%;
  left: 10%;
  text-shadow: none;
}
#carousel-area .carousel-item .carousel-caption h2 {
  font-family: 'Muli', sans-serif;
  font-size: 70px;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
}
#carousel-area .carousel-item .carousel-caption h3 {
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  color: #fff;
  font-weight: 100;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 1.2s;
  animation-duration: 1.2s;
}
#carousel-area .carousel-item .carousel-caption .btn {
  margin: 30px 15px;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 1.4s;
  animation-duration: 1.4s;
}
#carousel-area .active {
  opacity: 1;
}
#carousel-area .active.left,
#carousel-area .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}
#carousel-area .carousel-control {
  z-index: 10;
  width: 35px;
  height: 55px;
  top: 50%;
  padding: 10px 5px;
  box-shadow: none;
  background: url(/static/img/skin.png);
  margin-top: -35px;
  text-shadow: none;
}
#carousel-area .left.carousel-control {
  left: 5%;
}
#carousel-area .right.carousel-control {
  right: 5%;
  background-position: -150px 0px;
}
#carousel-area .carousel-indicators {
  bottom: 5px;
}
#carousel-area .carousel-indicators li {
  border-radius: 0px;
  width: 32px;
  height: 5px;
  margin: 0 5px;
  background: rgba(253, 254, 255, 0.80);
  border: none;
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}
#carousel-area .carousel-indicators li:hover,
#carousel-area .carousel-indicators li.active {
  opacity: 1;
  
}
#carousel-area:hover .carousel-control {
  display: block;
}
#carousel-area i {
  margin-right: 10px;
}
.big-title {
  font-family: 'Muli', sans-serif;
  font-size: 60px;
  color: #fff !important;
  text-transform: uppercase;
}
.medium-title {
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-size: 22px;
  line-height: 30px;
  font-weight: 300;
}
.mr {
  margin-right: 10px;
}
// .navbar {
//   margin-bottom: 0;
// }
// .navbar li {
//   color: #666;
// }
// .navbar-engage.affix {
//   width: 100%;
//   top: 0;
//   z-index: 99999;
//   box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
//   -webkit-animation-duration: 1s;
//   animation-duration: 1s;
//   -webkit-animation-fill-mode: both;
//   animation-fill-mode: both;
//   -webkit-animation-name: fadeInDown;
//   animation-name: fadeInDown;
// }
// .navbar-engage.affix .navbar-brand {
//   padding: 10px 15px;
//   height: 54px;
// }
// .navbar-engage.affix .navbar-brand img {
//   width: 100px;
// }
// .navbar-engage.affix .navbar-nav > li > a,
// .navbar-engage.affix .navbar-nav > li > a:focus {
//   margin: 10px 20px;
// }
// .navbar-brand {
//   width: 130px;
//   padding-top: 1.1rem;
//   height: auto;
// }
// .navbar-engage {
//   border: none;
//   border-radius: 0;
//   background: #fff;
// }
// .navbar-engage .navbar-nav > li > a,
// .navbar-engage .navbar-nav > li > a:focus {
//   font-size: 14px;
//   padding: 28px 20px;
//   margin: 0px;
//   outline: 0;
//   color: #666;
//   font-weight: 400;
//   background: transparent;
// }
// .navbar-engage .navbar-nav > li > a {
//   background: transparent;
//   padding-bottom: 5px;
//   color: #666;
//   position: relative;
// }
// .navbar-engage .navbar-nav > li > a:before {
//   content: "";
//   display: inline-block;
//   width: 0px;
//   height: 1px;
//   position: absolute;
//   left: 1px;
//   bottom: 4px;
//   -webkit-transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
//   -moz-transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
//   -o-transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
//   transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
// }
// .navbar-engage .navbar-nav > li a:hover::before,
// .navbar-engage .navbar-nav > li.active a::before,
// .navbar-engage .navbar-nav > li.open > a::before,
// .navbar-engage .navbar-nav > .active > a:focus::before {
//   width: 15px;
// }
// .navbar-engage .navbar-nav > li a:focus > a::before {
//   background: transparent;
// }
// .navbar-engage .navbar-nav > li.open a:focus {
//   background: transparent!important;
// }
// .navbar-engage .navbar-nav > li > a:hover,
// .navbar-engage .navbar-nav > .active > a,
// .navbar-engage .navbar-nav > li.open > a,
// .navbar-engage .navbar-nav > .active > a:hover,
// .navbar-engage .navbar-nav > .active > a:focus,
// .navbar-engage .navbar-nav > .open > a:hover {
//   background: transparent;
// }
nav.navbar {
  background: rgb(255, 255, 255);
}
nav.navbar-transparent {
  background: rgba(255, 255, 255, 0.7);
}
.dropdown-menu {
  
  -webkit-animation: fadeInUpMenu 0.4s;
  -moz-animation: fadeInUpMenu 0.4s;
  -ms-animation: fadeInUpMenu 0.4s;
  -o-animation: fadeInUpMenu 0.4s;
  animation: fadeInUpMenu 0.4s;
}
@-webkit-keyframes fadeInUpMenu {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes fadeInUpMenu {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInUpMenu {
  -webkit-animation-name: fadeInUpMenu;
  animation-name: fadeInUpMenu;
}
// .navbar-engage .dropdown-menu {
//   padding: 0;
//   border-style: solid;
//   border-width: 4px 0 0 0;
//   border-radius: 0;
//   left: 0;
//   right: 0;
// }
// .navbar-engage .dropdown-menu a.dropdown-item {
//   color: #666;
//   padding: 10px 0 10px 15px;
//   margin: 5px 0;
// }
// .navbar-engage .dropdown-menu a.dropdown-item:hover,
// .navbar-engage .dropdown-menu a.dropdown-item .active {
//   background: #ecf0f1;
// }
// .navbar-engage .dropdown-menu a {
//   background: #ecf0f1;
// }
// .navbar-engage .dropdown-item {
//   color: #666;
//   padding: 10px 0 10px 15px;
//   margin: 5px 0;
// }
// .navbar-engage .sub-menu {
//   top: -1px;
//   left: 100% !important;
//   padding: 0;
//   border: none;
//   -webkit-animation: fadeInUpMenu 0.4s;
//   -moz-animation: fadeInUpMenu 0.4s;
//   -ms-animation: fadeInUpMenu 0.4s;
//   -o-animation: fadeInUpMenu 0.4s;
//   animation: fadeInUpMenu 0.4s;
// }
// .navbar-engage .navbar-toggle {
//   border-radius: 0;
// }

@-webkit-keyframes fadeInUpMenu {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes fadeInUpMenu {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInUpMenu {
  -webkit-animation-name: fadeInUpMenu;
  animation-name: fadeInUpMenu;
}
// .navbar {
//   padding: 0px;
//   border-bottom: 1px solid #e4e4e4;
// }
// .nav-inline .nav-item + .nav-item {
//   margin-left: 0px;

// }


#main-menu ul > li > a {
  background: transparent!important;
  color: #333;
  position: relative;
  padding: 28px 20px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}
#main-menu ul > .dropdown:hover > ul.dropdown-menu {
  // display: block;
  -webkit-animation: MenuFadeIn 400ms;
  animation: MenuFadeIn 400ms;
  transition: all 0.3s ease-in-out 0s;
}
#main-menu ul .dropdown .dropdown-menu {
  padding: 0;
  border-radius: 0;
  left: 0;
  right: 0;
  margin-top: 0px;
  width: 220px;
  border: none;
  box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}
#main-menu ul .dropdown .dropdown-menu a {
  font-size: 13px;
  color: #666;
  // display: block;
  height: auto;
  padding: 12px 20px 13px;
  line-height: 16px;
  font-weight: 400;
  border-top: 1px solid #e2e6e7;
  transition: all 0.3s ease-in-out 0s;
}
#main-menu ul .dropdown .dropdown-menu a:hover,
#main-menu .dropdown .dropdown-menu a .active {
  background: transparent;
 
  transition: all 0.3s ease-in-out 0s;

}
#main-menu ul .dropdown .dropdown-menu > li > a:focus {
  background: transparent;
}
#main-menu ul .dropdown .dropdown-menu > li.active > a {
  background: transparent;
}
#main-menu ul .dropdown .open > a {
  background: transparent;
}
#main-menu ul .dropdown .open > a:hover,
#main-menu .dropdown .open > a:focus,
#main-menu .dropdown .open > a.active {
  background: transparent;
}
#main-menu ul .dropdown .sub-menu {
  top: -2px;
  left: 100% !important;
  padding: 0;
  border: none;
  -webkit-animation: MenuFadeIn 0.4s;
  -moz-animation: MenuFadeIn 0.4s;
  -ms-animation: MenuFadeIn 0.4s;
  -o-animation: MenuFadeIn 0.4s;
  animation: MenuFadeIn 0.4s;
}
#main-menu a.navbar-brand img {
  height: 48px;
} 
@-webkit-keyframes MenuFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes MenuFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
// #main-menu ul .dropdown-toggle::after {
//   display: none;
// }
.active.slicknav_collapsed,
.slicknav_parent.slicknav_open {
  background: #eee;
}
.wpb-mobile-menu {
  display: none;
}
ul li > ul {
  padding: 0;
  border-style: solid;
  border-width: 4px 0 0 0;
  border-radius: 0;
  left: 0;
  right: 0;
  border-color: transparent;
}
ul li > ul > li > a {
  color: #666;
  padding: 10px 0 10px 15px;
  margin: 5px 0;
}
ul li > ul > li > a:hover,
ul li > ul > li > a .active {
  background: #ecf0f1;
}
ul li > ul > li.active > a {
  background: #ecf0f1;
}
.slicknav_menu {
  display: none;
}
// @media screen and (max-width: 767px) {
//   .navbar-brand {
//     position: absolute;
//     top: 0;
//   }
//   /* #menu is the original menu */
//   #wpb-mobile-menu {
//     display: none;
//   }
//   .slicknav_menu {
//     display: block;
//   }
// }
/* SlickMenu responsive */
// @media screen and (max-width: 768px) {
//   .navbar-toggle {
//     display: none;
//   }
// }
.top_search .top_search_con {
  background: #fff;
  border: 1px solid #E4E4E4 !important;
  height: 38px;
  border-radius: 50px;
  position: relative;
  width: 100%;
}
.top_search .top_search_con input[type="text"],
.top_search .top_search_con input:focus {
  background: none !important;
  border: medium none;
  height: 100%;
  width: 100%;
  padding: 1px 20px 0 40px;
  color: #818B8D;
  font-size: 12px;
  outline: none;
}
.top_search .top_search_icon {
  
  font-size: 14px;
  left: 15px;
  position: absolute;
  top: 7px;
}
.top_search .top_search_submit {
  border: medium none;
  padding: 0;
  position: absolute;
  width: 20px;
  left: 12px;
  opacity: 0;
  top: 9px;
}

/* ==========================================================================
   Service Section Start
   ========================================================================== */
.service-item {
  text-align: center;
}
.service-item .icon-wrapper {
  font-size: 40px;
  margin-bottom: 30px;
}
.service-item .icon-wrapper i {
  display: block;
  margin: 0 auto;
  
}
.service-item h2 {
  font-size: 20px;
  margin-bottom: 15px;
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}
/* Pulse Shrink */
@-webkit-keyframes pulse-shrink {
  to {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
@keyframes pulse-shrink {
  to {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}
.pulse-shrink {
  display: inline-block;
}
.service-item:hover .pulse-shrink {
  -webkit-animation-name: pulse-shrink;
  animation-name: pulse-shrink;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}
/* ==========================================================================
   About Us Section Start
   ========================================================================== */
.split {
  background-color: #F6F6F6;
}

.split h2.title {
  font-size: 28px;
  text-transform: uppercase;
  margin: 10px 0 20px 0;
}
.split .lead {
  font-size: 14px;
}
.split .details-list {
  margin-top: 36px;
}
.split .details-list h3 {
  font-size: 16px;
   font-weight: 300;
}
/* ==========================================================================
   Featured Section Start
   ========================================================================== */
.featured-box {
  margin-top: 15px;
  margin-bottom: 15px;
}
// .featured-box .featured-icon {
//   // float: left;
//   // margin-top: 5px;
// }
.featured-box .featured-icon i {
  font-size: 28px;
  text-align: center;
  float: left;
  margin-right: 25px;
  margin-bottom: 85px;
  display: block;

}
.featured-box .featured-content {
  padding-left: 40px;
}
.featured-box .featured-content h4 {
  font-size: 22px;
  margin-bottom: 10px;
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}
.featured-box .featured-content p {
  font-size: 13px;
}
#portfolio {
  background: #F6F6F6;
}
#portfolio .mix {
  padding: 0;
}
#portfolio-list .mix {
  display: none;
}
.controls {
  text-align: center;
  padding: 20px;
}
.controls .active {

  
}
#portfolio-list {
  margin-top: 15px;
  display: inline-block;
}
.portfolio-img {
  overflow: hidden;
  display: block;
  position: relative;
}
.portfolio-img img {
  width: 100%;
}
.portfoli-content {
  width: 100%;
  position: absolute;
  height: 100%;
  opacity: 0;
  top: 0;
  -webkit-transition: opacity 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  transition: opacity 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}
.portfoli-content:before {
  background-color: rgba(255, 255, 255, 0.8);
  top: 15px;
  left: 15px;
  bottom: 15px;
  right: 15px;
  content: '';
  position: absolute;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}
.sup-desc-wrap {
  display: table;
  width: 100%;
  height: 100%;
}
.sup-desc-wrap .sup-desc-inner {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  padding: 0 35px;
}
.sup-desc-wrap .sup-link {
  margin-bottom: 20px;
}
.sup-desc-wrap .sup-link .left-link,
.sup-desc-wrap .sup-link .right-link {
  font-size: 14px;
  line-height: normal;
  outline: none;
  
  border-radius: 50%;
  color: #fff;
  
  width: 42px;
  height: 42px;
  opacity: 0;
  line-height: 42px;
  text-align: center;
  display: inline-block;
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
  margin-right: 5px;
}
.sup-desc-wrap .sup-link .left-link {
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}
.sup-desc-wrap .sup-link .right-link {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.sup-desc-wrap .sup-link a:hover {
  background: transparent;
  
}
.sup-desc-wrap .sup-meta-wrap .sup-title {
  display: block;
  outline: none;
  margin-bottom: 10px;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.sup-desc-wrap .sup-meta-wrap .sup-title h4 {
  font-size: 18px;
  color: #000;
  line-height: 22px;
  text-transform: uppercase;
  font-weight: 300;
}
.sup-desc-wrap .sup-meta-wrap .sup-description {
  font-size: 13px;
  line-height: 18px;
  color: #666;
  text-align: center;
  -webkit-transform: translateY(40px);
  transform: translateY(40px);
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.portfolio-item:hover .portfoli-content,
.portfolio-item:hover .portfoli-content:before {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.portfolio-item:hover .sup-title {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
.portfolio-item:hover .sup-description {
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}
.portfolio-item:hover .sup-link .right-link,
.portfolio-item:hover .sup-link .left-link {
  opacity: 1;
  webkit-transform: translateY(0px);
  transform: translateY(0px);
}
.loadmore-button {
  text-align: center;
  text-transform: uppercase;
  margin-top: 30px;
  color: #000;
}
#other-services {
  background: #fff;
}
#other-services .nav-tabs {
  border-bottom: none;
  float: left;
}
#other-services .nav-tabs li {
  float: none;
}
#other-services .nav-tabs li a {
  border: none;
  background: transparent;
  margin: 0;
  padding: 0;
}
#other-services .nav-tabs li a i {
  background: #81868e;
  font-size: 20px;
  text-align: center;
  color: #fff;
  float: left;
  width: 50px;
  border-radius: 50%;
  height: 50px;
  line-height: 50px;
  margin: 10px 0;
  cursor: pointer;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
#other-services .nav-tabs li a:hover i,
#other-services .nav-tabs li a:focus,
#other-services .nav-tabs li a.active i {
  
}
#other-services .tab-content {
  margin: 10px 0px 0px 80px;
}
#other-services .service-content h3 {
  font-size: 20px;
  margin-bottom: 25px;
  text-transform: uppercase;
}
#other-services .service-content p.lead {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 25px;
}
#other-services .service-content p {
  font-size: 14px;
}
#other-services .other-service-item {
  padding-bottom: 30px;
}
#other-services .other-service-item .icon {
  float: left;
}
#other-services .other-service-item .icon .icon-medium {
  background: #E74C3C;
  font-size: 24px;
  text-align: center;
  color: #fff;
  float: left;
  width: 70px;
  border-radius: 50%;
  height: 70px;
  line-height: 70px;
  margin: 12px 0;
  cursor: pointer;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
#other-services .other-service-item .service-content {
  padding-left: 100px;
}
#other-services .other-service-item .service-content h3 {
  
  font-size: 24px;
  clear: inherit;
}
#other-services .other-service-item .service-content p {
  padding: 0px 36px 0px 0px;
}
#other-services .other-service-item:hover .icon-medium {
  -webkit-transform: rotateY(360deg);
  -moz-transform: rotateY(360deg);
  -o-transform: rotateY(360deg);
  transform: rotateY(360deg);
  -webkit-transition: all 0.6s linear;
  -moz-transition: all 0.6s linear;
  -o-transition: all 0.6s linear;
  transition: all 0.6s linear;
  background: #666;
}
#other-services img {
  max-width: 100%;
  padding: 22px;
}
.skill p {
  margin-bottom: 10px;
  font-weight: 600;
}
.progress {
  background: #F5F5F5;
  height: 10px;
  margin: 0 0 20px;
  position: relative;
  width: 100%;
}
.progress-bar {
  float: left;
  height: 100%;
  font-size: 12px;
  color: #ffffff;
  border: 1px solid #fff;
  
  text-align: center;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.6s linear;
  -moz-transition: all 0.6s linear;
  -o-transition: all 0.6s linear;
  transition: all 0.6s linear;
  position: relative;
}
#pricing {
  background: url(/static/img/backgrounds/facts-bg.jpg);
}
#pricing .section-title {
  color: #fff;
}
#pricing .section-subcontent {
  color: #fff;
}
.pricing-table-1 {
  background: #fff;
  margin: 0 15px;
}
.pricing-table-1 .table-header {
  padding: 30px 0;
  color: #fff;
  
}
.pricing-table-1 .table-header h3 {
  font-size: 30px;
  font-weight: 400;
}
.pricing-table-1 .plan {
  border-bottom: 1px solid rgba(189, 195, 199, 0.5);
}
.pricing-table-1 .plan .price {
  font-size: 24px;
}
.pricing-table-1 .plan .period {
  font-size: 18px;
  color: #999;
  text-transform: uppercase;
}
.pricing-table-1 .plan-info {
  margin-top: 30px;
}
.pricing-table-1 .plan-info p {
  color: #bdc3c7;
  font-size: 16px;
  line-height: 30px;
}
.pricing-table-1 .button-area {
  padding: 30px 0 45px 0;
}
#pricing-block-x {
  background: #ecf0f1;
}
.pricing-table-x {
  background: rgba(0, 0, 0, 0.7);
  margin: 0 15px;
  padding: 15px 30px;
}
.pricing-table-x .table-header {
  padding: 15px 0;
  color: #fff;
}
.pricing-table-x .table-header h3 {
  font-size: 30px;
  font-weight: 400;
}
.pricing-table-x .plan .price {
  font-size: 30px;
  
}
.pricing-table-x .plan .period {
  font-size: 18px;
  color: rgba(231, 76, 60, 0.7);
  text-transform: uppercase;
}
.pricing-table-x .plan-info {
  margin-top: 30px;
}
.pricing-table-x .plan-info p {
  color: #fff;
  font-size: 16px;
  line-height: 16px;
}
.pricing-table-x .plan-info p i {
  margin-right: 5px;
}
.pricing-table-x .button-area {
  padding: 30px 0 40px 0;
}
#cool-facts {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}
#cool-facts .section-title {
  color: #fff;
}
#cool-facts .section-subcontent {
  color: #fff;
}
#cool-facts .facts-item {
  text-align: center;
  padding-bottom: 30px;
}
#cool-facts .facts-item i {
  font-size: 45px;
  
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 50%;
}
#cool-facts .fact-count h3 {
  color: #fff;
  font-weight: 300;
  font-size: 42px;
}
#cool-facts .fact-count h4 {
  color: #fff;
  font-size: 22px;
}
#notice {
  background: #f6f6f6;
}
#notice .notice-button {
  margin-top: 30px;
}
#notice h4 {
  font-size: 40px;
}
#notice p {
  font-size: 24px;
  color: #999;
  margin-top: 15px;
}
#notice .notice-content {
  padding: 30px;
}
#clients .client-item-wrapper {
  text-align: center;
  border: 1px solid #ddd;
  padding: 30px;
  margin: 0 5px;
  -moz-transition: all .6s ease;
  -webkit-transition: all .6s ease;
  transition: all .6s ease;
}
#clients .client-item-wrapper:hover {
  
}
#clients #clients-scroller img {
  opacity: 0.5;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  filter: gray;
  -moz-transition: all .6s ease;
  -webkit-transition: all .6s ease;
  transition: all .6s ease;
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
}
#clients #clients-scroller img:hover {
  filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
  filter: gray;
  -moz-transition: all .6s ease;
  -webkit-transition: all .6s ease;
  transition: all .6s ease;
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
  opacity: 1.0;
}
#clients #clients-scroller .owl-pagination {
  display: none;
}

#testimonial {
  background: #fff;
}
#testimonial .testimonial-inner {
  text-align: center;
  padding: 10px 30px;
  border: 1px solid #ddd;
  margin: 5px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
#testimonial .testimonial-inner:hover {
  
}
#testimonial .testimonial-images {
  margin-bottom: 20px;
}
#testimonial .testimonial-images img {
  width: 70px;
  height: 70px;
  margin: 0 auto;
  padding: 5px;
  box-shadow: 0px 0px 3px #ddd;
}
#testimonial .testimonial-content p {
  line-height: 25px;
  margin-bottom: 15px;
}
#testimonial .testimonial-footer {
  font-size: 12px;
  font-weight: 600;
}
#testimonial .testimonial-footer i {
  margin-right: 5px;
}
#testimonial .testimonial-footer a {
  color: #aaa;
  margin-left: 3px;
  font-weight: 300;
}
#testimonial .testimonial-footer a:hover {
  
}
#testimonial .owl-pagination {
  margin-top: 30px;
}
#testimonial .owl-pagination .owl-page span {
  border-radius: 4px;
  width: 8px;
  height: 8px;
  background: none;
  
  opacity: 0.5;
}
#testimonial .owl-pagination .owl-page.active span {
  
  opacity: 1.0;
}
#blog {
  background: #F6F6F6;
}
#blog .blog-item-wrapper {
  background: #fff;
  box-shadow: 0 1px 1px #ddd;
  padding: 15px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
#blog .blog-item-wrapper:hover {
  box-shadow: 0 0 40px #ddd;
}
#blog .blog-item-img {
  position: relative;
}
#blog .blog-item-img img {
  width: 100%;
}
#blog .blog-item-text {
  margin-top: 15px;
}
#blog .blog-item-text h3 {
  font-size: 18px;
  line-height: 30px;
}
#blog .blog-item-text h3 a {
  color: #555;
}
#blog .blog-item-text h3 a:hover {
  
}
#blog .blog-item-text .blog-one-footer {
  padding: 10px 0px 0px;
  border-top: 1px dashed #ddd;
}
#blog .blog-item-text .blog-one-footer a {
  font-size: 12px;
  padding-right: 15px;
  color: #999;
}
#blog .blog-item-text .blog-one-footer a:hover {
  
}
#blog .blog-item-text .blog-one-footer i {
  
  margin-right: 5px;
}
#team .team-item figure {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 0;
}
#team .team-item figure img {
  width: 100%;
  height: auto;
}
#team .team-item figure figcaption {
  height: 100%;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.85);
  opacity: 0;
  width: 100%;
  -webkit-transition: opacity 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  transition: opacity 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}
#team .team-item figure figcaption .details {
  position: absolute;
  top: 30%;
}
#team .team-item figure figcaption .content-white {
  font-size: 13px;
  text-align: center;
  color: #FFF;
  margin: 0 auto;
  line-height: 18px;
  width: 65%;
  -webkit-transform: translateY(-50px);
  transform: translateY(-50px);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
#team .team-item figure figcaption .orange-line {
  
  margin: 12px auto 6px;
  width: 0px;
  height: 1px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
#team .team-item figure:hover figcaption {
  opacity: 1;
}
#team .team-item figure:hover .orange-line {
  width: 62px;
}
#team .team-item figure:hover figcaption .content-white {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}
#team .team-item figure:hover .social a {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}
#team .team-item .social {
  text-align: center;
  margin-top: 15px;
}
#team .team-item .social .facebook,
#team .team-item .social .twitter,
#team .team-item .social .google-plus {
  font-size: 14px;
  line-height: normal;
  outline: none;
  
  border-radius: 50%;
  color: #fff;
  
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  display: inline-block;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
  margin-right: 5px;
}
#team .team-item .social .facebook {
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
}
#team .team-item .social .twitter {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
#team .team-item .social .google-plus {
  -webkit-transition: all 600ms ease;
  transition: all 600ms ease;
}
#team .team-item .social a:hover {
  background: transparent;
  
}
#team .info {
  background: #F6F6F6;
  padding: 15px 0 1px;
  text-align: center;
}
#team .info h2 {
  color: #000;
  font-size: 18px;
}
#team .info p {
  color: #000;
  font-size: 12px;
  font-weight: 300;
  line-height: 22px;
  text-transform: uppercase;
}
.orange-line {
  
  margin: 12px auto 6px;
  width: 52px;
  height: 1px;
}
#team-block-2 .team-member {
  padding-bottom: 15px;
  margin-top: 20px;
  border-bottom: 5px solid transparent;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  transition: all 0.2s linear;
  background: #666;
  border-bottom: 4px solid transparent;
}
#team-block-2 .team-member .face {
  position: relative;
  width: 100%;
  overflow: hidden;
}
#team-block-2 .team-member .face img {
  width: 100%;
  height: auto;
}
#team-block-2 .team-member .member-info {
  text-align: center;
  padding: 24px;
}
#team-block-2 .team-member .member-info h3 {
  line-height: 30px;
  font-size: 30px;
  color: #fff;
}
#team-block-2 .team-member .member-info .position {
  color: #999;
  font-size: 16px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
}
#team-block-2 .team-member .member-info .social-team {
  margin-top: 15px;
}
#team-block-2 .team-member .member-info .social-team a {
  color: #3D566E;
  margin: 0 4px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
#team-block-2 .team-member .member-info .social-team a i {
  color: #ffffff;
  padding: 7px;
  background: transparent;
  font-size: 18px;
  line-height: 28px;
  border: 1px solid #fff;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
#team-block-2 .team-member .member-info .desc {
  margin-top: 15px;
  color: #ecf0f1;
}
#team-block-2 .team-member .member-info .social .fa-facebook:hover {
  background: #3b5998;
  color: #fff;
}
#team-block-2 .team-member .member-info .social .fa-twitter:hover {
  background: #00CAFB;
  color: #fff;
}
#team-block-2 .team-member .member-info .social .fa-google-plus:hover {
  background: #D23A1D;
  color: #fff;
}
#team-block-2 .team-member .member-info .social .fa-linkedin:hover {
  background: #007bb6;
  color: #fff;
}
#team-block-2 .team-member:hover {
  
  background: #777;
}
#subscription .subscription-form .form-control {
  height: 60px;
  font-size: 24px;
  color: #999;
}
#subscription .subscription-form .btn {
  padding: 18px;
}
#error-404 {
  background: #ECF0F1;
  padding: 36px;
}
#error-404 h1 {
  font-size: 120px;
}
#error-404 h3 {
  font-size: 40px;
  margin-bottom: 30px;
}
#content {
  padding-top: 90px;
  padding-bottom: 30px;
  min-height: 600px;
}
#contact .contact-info-wrapper {
  margin-top: -60px;
}
#contact .contact-info-wrapper .contact-item-wrapper i {
  padding: 15px;
  
  width: 64px;
  height: 64px;
  border-radius: 50%;
  color: #fff;
}
#contact .map {
  position: relative;
}
#contact .contact-info {
  background: #666;
  color: #fff;
  padding: 30px 0;
}
#contact .contact-form-wrapper {
  background: url(/static/img/backgrounds/contact-form-bg.jpg);
  background-size: cover;
}
#contact .form-control {
  margin-bottom: 45px;
  background: transparent;
  color: #fff;
}
#contact2 .contact-info-wrapper {
  margin-top: -10px;
}
#contact2 .contact-info-wrapper .contact-item-wrapper {
  padding: 15px 30px;
}
#contact2 .contact-info-wrapper .contact-item-wrapper i {
  color: #fff;
  float: left;
  padding: 8px 15px;
  font-size: 24px;
}
#contact2 .contact-info-wrapper .contact-item-wrapper h4 {
  line-height: 40px;
  color: #FFF;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}
#contact2 .form-control {
  margin-bottom: 30px;
}
footer {
  background: #282828;
  color: #7C7C7C;
}
footer .small-title {
  margin-bottom: 30px;
  color: #fff;
}
footer ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
footer ul li {
  line-height: 25px;
}
footer ul li a {
  color: #999;
}
footer ul li.tweet {
  margin-bottom: 15px;
}
footer ul li.tweet .fa-twitter {
  padding: 5px;
  color: #fff;
  
  border-radius: 100%;
  width: 24px;
  height: 24px;
}
footer ul li.tweet .tweet-date {
  font-size: 12px;
  font-style: italic;
  color: #bdc3c7;
}
footer .plain-flicker-gallery {
  margin-right: -5px;
  margin-left: -5px;
}
footer .plain-flicker-gallery a {
  display: inline-block;
  width: 32%;
  font-size: 0px;
  padding: 5px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
footer .plain-flicker-gallery a:hover {
  opacity: 0.7;
}
footer .plain-flicker-gallery a img {
  width: 100%;
}
footer .contact-us .form-control {
  color: #999;
  font-size: 14px;
  background: #1D1D1D;
  border-radius: 0px;
  border: 1px solid transparent;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
footer #copyright {
  background: #1D1D1D;
}
footer #copyright .row {
  padding: 15px 0;
}
footer #copyright .copyright-text,
footer #copyright .nav-inline {
  margin-top: 15px;
}
.social-footer a .nc-icon {
  font-size: 14px;
  height: 35px;
  line-height: 35px;
  margin: 4px;
  width: 35px;
}
/* .tagcloud{
   a{
      color: #FFF;
      font-size: 9pt;
      display: inline-block;
      float: left;
      margin: 0px 10px 10px 0px;
      padding: 2px 10px;
      background: #666;
      border-radius: 30px;
      &:hover{background: #565454;}
      i{padding-right: 5px;}
    }
  } */

/* Shortcode Accordion Page Styles */
  .panel-default .panel-heading {
  border: medium none;
  border-radius: 0;
  outline: medium none;
  padding: 0;
  width: 100%;
}
.panel-default .panel-heading a {
  color: #ffffff;
  display: inline-block;
  font-weight: 500;
  padding: 12px 35px 12px 15px;
  position: relative;
  text-decoration: none;
  width: 100%;
  font-size: 20px;
}
.panel-default .panel-heading a i {
  font-size: 22px;
  padding-right: 10px;
}
.panel-default .panel-heading a::after {
  color: #fff;
  content: "";
  font-family: "FontAwesome";
  font-size: 22px;
  font-weight: 300;
  line-height: 1;
  margin-top: -10px;
  position: absolute;
  right: 15px;
  top: 50%;
}
.panel-default .panel-heading .collapsed::after {
  content: "";
}
.accordionSmall .panel-default .panel-heading a::after {
  content: "";
}
.accordionSmall .panel-default .panel-heading .collapsed::after {
  content: "";
}
.panel-default .panel-collapse .panel-body {
  background-color: #fafafa;
  padding: 15px;
}
#accordionRound .panel-default .panel-heading a {
  border-radius: 8px 8px 0px 0px;
}
.panel-transparent .panel-heading a {
  background: #F5F5F5;
  color: #000;
}
.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em;
}
.panel-image .panel-body img {
  padding-bottom: 1px;
}
/* Shortcode Tabs Page Styles */

#default-tab ul.nav-tabs {
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 10px;
  padding-bottom: 5px;
}
ul.nav-tabs li {
    float: left;
    margin-top: 0;
}
ul.nav-tabs li a {
  border: medium none !important;
  color: #a0a0a0;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 10px;
  padding: 10px 15px;
  text-transform: uppercase;
}
#default-tab .nav-tabs > li > a.active , #default-tab .nav-tabs > li a.active:focus, #default-tab .nav-tabs > li a.active:hover {
    border: 0 none;
    position: relative;
}
#default-tab ul.nav-tabs > li a.active::before {
  bottom: -14px;
  content: "";
  height: 3px;
  left: 0;
  position: absolute;
  width: 100%;
}
#round-tab ul.nav-tabs {
  margin-bottom: 10px;
  
}

#round-tab ul li a.active {
    -moz-border-bottom-colors: none !important;
    -moz-border-left-colors: none !important;
    -moz-border-right-colors: none !important;
    -moz-border-top-colors: none !important;
    border-bottom: 0 none;
    border-image: none !important;
    margin-bottom: 0;
   
}
#round-tab ul li a {
    margin-bottom: 0;
    padding: 15px !important;
    text-transform: capitalize;
}
#vertical-tab .nav-tabs, #vertical-tab .tab-content {
  display: table-cell;
  vertical-align: top;
}
#vertical-tab .tab-content {
  padding: 0 25px;
}
#vertical-tab .tab-pane p {
  line-height: 28px;
}
#vertical-tab ul.nav-tabs {
  border: 1px solid #b9b9b9 !important;  
  padding-right: 0px;
}

#vertical-tab .nav-tabs li {
  float: none;
  margin: 0;
}

#vertical-tab ul.nav-tabs li a {
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  padding: 20px 40px;
  white-space: nowrap;
  width: 100%;
  border-radius: 0;
  background: #EEEEEE;
  color: #676767;
  border-bottom: 3px solid #b9b9b9 !important;
}

#vertical-tab ul.nav-tabs li a .fa {
  display: block;
  font-size: 27px;
  margin-bottom: 10px;
  text-align: center;
}
#vertical-tab ul.nav-tabs li a.active > .fa {
  
}
#vertical-tab ul.nav-tabs {
    border: 0 none;
}
#vertical-tab ul.nav-tabs li a.active {
    background: #fff none repeat scroll 0 0;
    color: #202020;
    
}
#tab-with-icon .nav-tabs {
  border-bottom: medium none;
  float: left;
}
#tab-with-icon .nav-tabs li {
  float: none;
}
#tab-with-icon .nav-tabs li a {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  margin: 0;
  padding: 0;
}

#tab-with-icon .nav-tabs li a i {
  background: #81868e none repeat scroll 0 0;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  float: left;
  font-size: 25px;
  height: 70px;
  line-height: 70px;
  margin: 10px 0;
  text-align: center;
  transition: all 0.5s ease 0s;
  width: 70px;
}

#tab-with-icon .tab-content {
  margin: 20px 0 0 100px;
}
#tab-with-icon h3 {
  font-size: 20px;
  margin-bottom: 25px;
  text-transform: uppercase;
}
#tab-with-icon p.lead {
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 25px;
}
#tab-with-icon .nav-tabs li a:hover i, #tab-with-icon .nav-tabs li a:focus, #tab-with-icon .nav-tabs li a.active i {
  
}

/* Shortcode Buttons */
.outline-buttons-area , .block-button-area , .animated-progress-area {
  background: #FAFAFA;
  padding: 50px 0;
}

.btn .fa {
  padding-right: 5px;
}
.grey-progress-bar .progress-bar {
  background: #e0e0e0 none repeat scroll 0 0;
}
.skill-striped .progress-bar{
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 40px 40px;
}
.skill-animated .progress-bar {
    -webkit-animation: 2s linear 0s normal none infinite running progress-bar-stripes;
            animation: 2s linear 0s normal none infinite running progress-bar-stripes;
}

/* Shortcode testimonial area */
#testimonial-dark .testimonial-inner {
  background: #333 none repeat scroll 0 0;
  border: 1px solid #c1c1c1;
}
#testimonial-dark .testimonial-images img {
  box-shadow: 0 0 3px #fff;
}
#testimonial-dark .testimonial-content p {
  color: #fff;
}
.single-testimonial-area {
  position: relative;
  width: 100%;
  background: url(/static/img/shortcode/testimonial/single-testimonial-bg.jpg);
  padding: 100px 0;
}
.single-testimonial-area:before {
  background: rgba(51, 51, 51, 0.8);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  content: "";
  display: block;
}

.single-testimonial-area h3 {
    color: #fff;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
}
.single-testimonial-area p {
    color: #fff;
    text-align: center;
}
#single-testimonial-item .testimonial-inner {
  padding: 80px;
  position: relative;
}
#single-testimonial-item .testimonial-inner blockquote {
  color: #fff;
  font-size: 14px;
  position: relative;
}
#single-testimonial-item .testimonial-images img {
  box-shadow: 0 0 3px #ddd;
  height: 90px;
  margin: 0 auto;
  padding: 5px;
  width: 90px;
}
#single-testimonial-item .testimonial-footer {
    color: #fff;
    padding-top: 15px;
}

#single-testimonial-item .testimonial-footer a {
    
}
#single-testimonial-item .testimonial-inner::before {
    color: #fff;
    content: "";
    font-family: fontawesome;
    font-size: 45px;
    left: 25px;
    position: absolute;
    top: 70px;
}

#single-testimonial-item .testimonial-inner::after {
  background: rgba(51, 51, 51, 0.6) none repeat scroll 0 0;
  border-radius: 5px;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  border: 1px solid #ddd;
}
#single-testimonial-item .owl-pagination .owl-page.active span {
  
}
#single-testimonial-item .owl-pagination .owl-page span {
 
}

/* Shortcode clients */
#color-client-scroller .client-item-wrapper {
  
}
#color-client-scroller .client-item-wrapper img {
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    opacity: 0.5;
    transition: all 0.6s ease 0s;
}
#color-client-scroller .client-item-wrapper img:hover {
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
    opacity: 1;
    transition: all 0.6s ease 0s;
}


/*Short code icons style*/
i.icon-default {

  font-size: 50px;
  margin-bottom: 30px;
  margin-right: 30px;
  vertical-align: middle;
}
i.icon-round {
  
  border-radius: 100%;
  color: #fff;
  font-size: 50px;
  height: 100px;
  line-height: 100px;
  margin-bottom: 13px;
  margin-right: 13px;
  text-align: center;
  vertical-align: middle;
  width: 100px;
}
i.icon-round-border {
  
  border-radius: 100%;
  
  font-size: 45px;
  height: 100px;
  line-height: 100px;
  margin-bottom: 12px;
  margin-right: 12px;
  text-align: center;
  vertical-align: middle;
  width: 100px;
}
i.icon-box {
  
  border-radius: 4px;
  color: #fff;
  font-size: 45px;
  height: 100px;
  line-height: 100px;
  margin-bottom: 12px;
  margin-right: 12px;
  text-align: center;
  vertical-align: middle;
  width: 100px;
}
i.icon-box-border {
  
  border-radius: 4px;
  
  font-size: 45px;
  height: 100px;
  line-height: 100px;
  margin-bottom: 12px;
  margin-right: 12px;
  text-align: center;
  vertical-align: middle;
  width: 100px;
}
i.icon-xs {
  font-size: 16px !important;
  height: 30px !important;
  line-height: 30px !important;
  margin-bottom: 7px !important;
  margin-right: 7px !important;
  width: 30px !important;
}
i.icon-sm {
  font-size: 27px !important;
  height: 50px !important;
  line-height: 50px !important;
  margin-bottom: 14px !important;
  margin-right: 14px !important;
  width: 50px !important;
}
i.icon-md {
  font-size: 35px !important;
  height: 75px !important;
  line-height: 75px !important;
  margin-bottom: 14px !important;
  margin-right: 14px !important;
  width: 75px !important;
}

i.icon-lg {
  font-size: 50px !important;
  height: 100px !important;
  line-height: 100px !important;
  margin-bottom: 13px !important;
  margin-right: 13px !important;
  width: 100px !important;
}

#image-carousel .item img {
  height: 200px;
  width: 250px;
}

/* Single portfolio */
.single-portfolio-area .single-portfolio-image img {
  width: 100%;
}
.portfolio-meta ul , .portfolio-controls ul {
  list-style: outside none none;
  padding-left: 0;
}
.portfolio-meta ul li {
  margin: 10px 0;
}
.portfolio-meta ul li span {
  
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  text-decoration: underline;
  text-transform: uppercase;
  width: 130px;
  text-decoration-style: double;
}

.portfolio-controls ul li a.btn {
    padding: 10px 75px;
}
.portfolio-controls ul li a.btn i.fa {
    font-size: 37px;
}

/* Blog Post Section */
.blog-post-wrapper {
  border: 1px solid #eee;
  margin-bottom: 50px;
}
.blog-post-wrapper .format-video iframe {
  min-height: 350px;
  width: 100%;
}
.blog-post-wrapper .format-audio iframe {
  height: 200px;
  width: 100%;
}
.author-info , .blog-post-content {
  padding: 20px;
}
.tag-posted-in li a {
  color: #8F8F8F; 
}
.tag-posted-in li i.fa {
  color: #8F8F8F;
  padding-right: 3px;
}
span.published-time {
  font-size: 12px;
}
span.published-time i.fa {
  margin-right: 5px;
} 
.featured-wrapper img {
  width: 100%;
  height: 350px;
}
.blog-post-content h2.blog-post-title {
  font-size: 26px;
  font-weight: 600;
  line-height: 26px;
  text-transform: capitalize;
  margin-bottom: 20px;
}
.blog-post-footer {
  padding: 20px;
  border-top: 1px solid #eee;
  background-color: #fff;
}
.blog-post-footer ul.post-meta {
    list-style: outside none none;
}
.blog-post-footer ul.post-meta li {
    float: left;
    margin-right: 10px;
}
.blog-post-footer ul li a {
  background: #fff none repeat scroll 0 0;

  display: block;
  font-size: 10px;
  line-height: 28px;
  padding: 4px 15px;
  text-transform: uppercase;
  transition: all .3s ease-in-out;
}
.blog-post-footer ul li a:hover {

  color: #fff;
  transition: all .3s ease-in-out;
}

.blog-post-content blockquote.blockquote {

}
.blog-post-content blockquote.blockquote p {
    color: #969595;
    font-size: 16px;
    font-weight: 500;
}
.blog-post-content blockquote.blockquote a {
  font-size: 15px;
  font-style: italic;
  float: right;
}
.blog-post-wrapper.format-qoute {
  border: none !important;
}
.blog-pagination nav ul.pagination li.page-item a {
    border-radius: 0;
    margin-right: 15px !important;
    color: #333
}
.blog-pagination nav ul.pagination li.page-item.active a , .blog-pagination nav ul.pagination li.page-item a:hover , .blog-pagination nav ul.pagination li.page-item a:focus  {

    color: #fff;
}

.sidebar-area .widget {
  margin-bottom: 60px;
}
.sidebar-area .search-bar {
  position: relative;
}
.sidebar-area .search-bar .form-control {
  border-radius: 0;
  color: #333 !important;
  font-size: 13px;
  height: 46px;
}
.form-control:focus {

}
.sidebar-area .search-bar button {
  background-color: transparent;
  border: 0 none;
  height: 46px;
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
}
.sidebar-area .search-bar button i.fa {

}
.sidebar-area .search-bar button .fa {
  color: #333;
}

h2.widget-title {
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 20px;

}
.widget ul {
    list-style: outside none none;
    padding-left: 0;
}
.widget.popular-post ul li {
  margin-bottom: 20px;
}
.widget.popular-post h4 {
    font-size: 16px;
}
.category-menu a {
    color: #333;
    font-size: 15px;
    transition: all 0.2s;
}
.category-menu a .fa {
    padding-right: 5px;
    transition: all 0.2s;
}
.category-menu li {
    border-bottom: 1px solid #333;
    padding: 10px 0;
}
.category-menu a:hover .fa {
  padding-right: 10px;
  transition: all 0.2s;
}

.widget.tag-cloud li {
    float: left;
    margin-bottom: 5px;
    margin-right: 5px;
}
.widget.tag-cloud a {
  color: #fff;
  display: block;
  font-size: 10px;
  font-weight: 600;
  line-height: 28px;
  padding: 0 11px;

  transition: all 0.3ms ease-in-out;
}
.widget.tag-cloud a:hover {
    opacity: 0.8;
    transition: all 0.3ms ease-in-out;

}
.widget.subscribe-widget .form-control {
  font-size: 14px;
  height: 42px;
  padding: 10px;
  width: 255px;
}
.widget-ad img {
  width: 100%;
}
.blog-post blockquote {
  margin: 40px 0;
}
.blog-post h3 {
  margin-top: 30px;
}
.blog-post > p {
  line-height: 29px;
}

.blog-post ul li {
    font-size: 15px;
    padding: 6px 0;
    font-weight: 600;
}
.blog-post ol li {
    font-size: 15px;
    padding: 6px 0;
    font-weight: 600;
}
#copyright ul li.nav-item {
  padding-right: 10px;
  text-transform: capitalize;
}
.testimonial-footer .fa {
    padding-right: 6px;
}
.testimonial-footer a {
    padding-left: 5px;
}

.blog-post img {
  width: 100%;
}
.blog-post pre {
  background: #eee none repeat scroll 0 0;
  margin: 20px 0;
}
.about-author-section {
  border: 1px solid #eee;
}
.about-author-section .media {
  padding: 20px;
}
.about-author-section .media .media-body {
  padding-left: 10px;
}
.about-author-section .media .media-body p {
  line-height: 28px;
}
.about-author-section .author-footer {
  border-top: 1px solid #eee;
  padding: 20px;
}
.similar-post img {
  width: 100%;
}
.similar-post h2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  margin-top: 10px;
}
.no-pdl {
  padding-left: 0 !important;
}
.comments-area ul {
  padding-left: 0;
}
.comments-area .media {
  margin-top: 40px;
}
.comments-area .media-body {
  padding-left: 10px ;
}
.comment-author span {
  padding-left: 5px;
}
.new-comment .form-group .form-control {
  background: #f2f2f2 none repeat scroll 0 0;
  border-radius: 0;
  color: #333 !important;
  font-size: 15px;
  padding: 12px;
}
/* Call to action */
.cta-section {
  background: url(/static/img/backgrounds/cta-bg.jpg) scroll center center;
  width: 100%;
  position: relative;
}
.cta-section::before {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  content: "";
  background-color: rgba(51, 51, 51, 0.9);
}
.cta-area {
  display: table;
  width: 100%;
  height: 150px;
}
.cta-content {
  display: table-cell;
  vertical-align: middle;
}
.cta-content h2 {
    color: #fff;
    font-size: 38px;
    font-weight: 400;
    line-height: 38px;
}
.cta-content p {
    color: #fff;
    font-size: 18px;
    line-height: 28px;
    padding-top: 10px;
}
.cta-content .btn {
  font-size: 16px;
  margin-top: 15px;
  padding: 13px 24px;
  text-transform: uppercase;
}
.page-header-section {
  background: url(/static/img/backgrounds/testimonial-bg.jpg);
  width: 100%;
}
.page-header-area {
  display: table;
  height: 80px;
  width: 100%;
}
.page-header-content {
  display: table-cell;
  vertical-align: middle;
}
.page-header-content h2 {
  color: #fff;
  font-size: 36px;
  font-weight: 600;
  text-transform: capitalize;
}
#google-map-shortcode {
  padding: 0;
  height: 400px;
}
#google-map-contact {
  padding: 0;
  height: 300px;
}
#google-map-contact2 {
  padding: 0;
  height: 200px;
}
.error-section h1 {
    color: #ff2a40;
    font-size: 10rem;
}
.col-md-12.text-xs-center > h4 {
}
.error-section h4 {
    text-transform: capitalize;
}
.contact-title-text h2 {

  font-size: 50px;
}
.contact-title-text p {
  font-size: 18px;
  margin-top: 15px;
}
.contact-widget {
  margin-bottom: 30px;
}
.contact-widget h2 {
  margin-bottom: 20px;
}
.contact-widget address {
  line-height: 45px;
}
.contact-widget.office-location span {
  font-weight: 600;
}
#contactForm .form-group .contact-control {
  background: #f8fafd none repeat scroll 0 0;
  border-radius: 0;
  height: 50px;
}
#contactForm .form-group {
  margin-bottom: 25px;
}
#contactForm .form-group .form-control {
  border-radius: 0;
  background: #f2f2f2 none repeat scroll 0 0;
  font-size: 14px;
} 

ul.contact-info2 {
    list-style: outside none none;
    margin-top: 20px;
    padding: 0;
}

ul.contact-info2 i.fa {
    margin-right: 10px;
}
.loadmore-button .btn {
  padding: 14px 35px;
}
.about2-intro-image img {
  width: 100%;
}
.service2-single-service {
  text-align: center;
  margin: 20px 0;
}

.service2-single-service .fa {
    
    border-radius: 50%;
    color: #fff;
    font-size: 23px;
    height: 75px;
    line-height: 75px;
    margin-bottom: 20px;
    width: 75px;
}
.service2-single-service h2 {
    font-size: 20px;
    text-transform: uppercase;
}
.service2-single-service p {
    font-size: 13px;
}
.new-comment .btn {
  font-size: 16px;
  padding: 13px 30px;
}

.comments-area .media.reply {
  background: #E5F7FD none repeat scroll 0 0;
  padding: 20px;
  border-radius: 4px;
}
.blog-pagination {
  text-align: center;
}
.pricing-table2 {
  padding: 15px 30px;
}
.single-pricing-2 {
  border: 1px solid #dedede;
}
.single-pricing-2 .plan-name {
  color: #959595;
  display: block;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  padding: 20px 0;
}
.single-pricing-2 .plan-name small {
  display: block;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
}
.single-pricing-2 .plan-price {
  background: #f2f2f2 none repeat scroll 0 0;
  font-size: 48px;
  margin-bottom: 30px;
  padding: 30px 0;
  text-align: center;
}
.featured .plan-price {
  color: #fff !important;
  background: #ff5959;
}
.premium .plan-price {
  background: #3498db !important;
  color: #fff !important;
}
.single-pricing-2 .plan-price .currency {
  font-size: 50%;
  font-weight: 700;
  left: -0.01em;
  top: -0.9em;
}
.single-pricing-2 .plan-price sub {
  font-size: 18px;
  font-weight: 700;
}
.single-pricing-2 ul {
  background: #fff none repeat scroll 0 0;
  margin-bottom: 0;
  padding: 0;
}
.single-pricing-2 ul li {
  border-top: 1px dashed #dedede;
  font-weight: 500;
  padding: 12px 29px;
  text-align: center;
}
.single-pricing-2 ul li:first-child {
  border-top: 0;
}
.pricing2-button {
    padding: 20px 0;
    text-align: center;
}
.pricing2-button .btn {
    padding: 12px 55px;
    text-transform: uppercase;
}
/* Roof bar styles */
#roof {
  background: #3B3B3B;
}
.roof-content {
  display: table;
  height: 60px;
  width: 100%
}
.roof-middle {
  display: table-cell;
  vertical-align: middle;
}
.roof-menu{
  margin-right: 15px;
}
.roof-social-icons a i.fa {
  background: #484848 none repeat scroll 0 0;
  border-radius: 50%;
  font-size: 13px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  width: 35px;
  color: #fff;
  margin-right: 10px;
}
.roof-social-icons a i.fa:hover {
  
}
.roof-menu ul {
  list-style: none;
}
.roof-menu ul li {
  float: left;
}
.roof-menu ul li a {
  color: #fff;
  font-size: 12px;
  line-height: 35px;
  margin-right: 16px;
  padding: 6px 9px;
  text-transform: uppercase;
}
.roof-menu ul li.active a {
    
    border-radius: 3px;
    margin-right: 0;
}
#default-tab a.nav-link {
  background: none;
}
.dropdown-item.active, .dropdown-item.active:focus, .dropdown-item.active:hover {
  background-color: #eee;
  
}
.nav-link.active {
  
}
/*.controls a.active {
  background: rgba(0, 0, 0, 0.47);
}*/
/* Tablet Layout: 768px. */
@media only screen and (min-width: 768px) and (max-width: 991px) { 
  #main-menu ul > li > a {
    font-size: 12px !important;
    padding: 18px 10px;
  }
  #main-menu ul .dropdown .dropdown-menu a {
    border-top: 1px solid #e2e6e7;
    color: #666;
    display: block;
    font-size: 10px;
    font-weight: 400;
    height: auto;
    line-height: 16px;
    padding: 9px 19px 7px;
    transition: all 0.3s ease-in-out 0s;
  }
  #main-menu a.navbar-brand img {
    height: 36px;
  }   
  #carousel-area .carousel-item .carousel-caption h2 {
    font-size: 50px;
  }
  #carousel-area .carousel-item .carousel-caption h3 {
    font-size: 20px;
  }
  #carousel-area img {
    height: 100%;
  }
  .service-item h2 {
    font-size: 15px !important;
  }
  .top_search {
    display: none;
  }  
  .pricing-table-x .plan-info p {
    font-size: 12px;
  }
  #team .team-item figure figcaption .content-white {
    font-size: 9px;
    line-height: 12px;
    width: 60%;
  }
  #team .team-item .social .facebook, #team .team-item .social .twitter, #team .team-item .social .google-plus {
    font-size: 8px;
    height: 25px;
    line-height: 25px;
    width: 25px;
  }
  .section-title {
    font-size: 24px !important;
  }
  .footer-widget p {
    font-size: 12px;
  }
  .social-footer a .fa {
    height: 25px;
    line-height: 25px;
    width: 25px;
  }
  footer .plain-flicker-gallery a {
    width: 31%;
  }
  footer ul li.tweet {
    font-size: 12px !important;
  }
  #copyright p, #copyright a {
    font-size: 10px !important;
  }
  .single-pricing-2 .btn {
    padding: 10px 15px;
    font-size: 10px;
  }
  .single-pricing-2 .plan-price {
    font-size: 35px;
  }
  .single-pricing-2 ul li {
    font-size: 12px;
  }
  ul.nav-tabs li a {
    font-size: 11px;
  }
  .btn-lg {
    padding: 12px 20px;
  }
  .btn-md {
    padding: 12px 14px;
  }
  .pricing-table h3 {
    font-size: 24px !important;
  }
  .single-pricing-2 .plan-name {
    font-size: 18px;
  }
  .sup-desc-wrap .sup-meta-wrap .sup-description {
    display: none;
  }
  .portfolio-meta ul li span {
    font-size: 10px;
    width: 78px;
  }
  .portfolio-meta ul li {
    font-size: 10px;
  }
  span.published-time {
    font-size: 10px;
  }
  .widget.popular-post h4 {
    font-size: 12px;
  }
  .flickr-widget .plain-flicker-gallery a img {
    width: 31%;
  }
  .widget.tag-cloud a {
    font-size: 8px;
  }

}

@media (max-width: 767px) {
  // .nav-inline .nav-item + .nav-item {
  //   margin-left: 0;
  //   float: none;
  // }
  // #main-menu ul .dropdown .dropdown-menu {
  //   display: block;
  //   position: relative;
  //   width: 100%;
  // }
  // #main-menu {
  //   clear: both;
  //   width: 100%;
  // }
  // nav.navbar.navbar-light {
  //   min-height: 73px;
  // }
  // nav.navbar.navbar-light button.navbar-toggler {
  //   background: #333 none repeat scroll 0 0;
  //   border-radius: 0 !important;
  //   margin-top: 17px;
  // }
  // nav.navbar.navbar-light ul.nav-inline .nav-item {
  //   display: block;
  // }
  // nav.navbar.navbar-light ul.nav-inline {
  //   max-height: 350px;
  //   overflow-x: scroll;
  //   margin-top: 20px;
  // }
  #roof {
    display: none;
  }
  #main-menu ul > li > a {
    font-size: 12px !important;
    padding: 18px 10px;
  }
  .page-header-content h2 {
    text-align: center;
  }
  .footer-widget {
    margin-bottom: 40px;
  }
  .carousel-item > img {
    height: 100% !important;
  }
  #carousel-area .carousel-item .carousel-caption h2 {
    font-size: 20px;
    font-weight: 600;
  }
  #carousel-area .carousel-item .carousel-caption h3 {
  font-size: 16px;
  }
  .btn-lg {
    font-size: 10px;
    padding: 10px 16px;
    text-transform: uppercase;
  }
  .section-title {
    font-size: 18px;
  }
  .section-subcontent {
    font-size: 12px;
    line-height: 17px;
  }
  #portfolio a.filter {
    font-size: 10px;
    margin: 5px;
    padding: 5px 11px;
  }
  .controls {
    padding: 0;
  }
  .pricing-table-x .table-header h3 {
    font-size: 18px;
  }
  .plan-info > p {
    font-size: 12px !important;
  }
  .pricing-table-x .button-area {
    padding: 14px 0 40px;
  }
  .pricing-table-x {
    margin: 20px 15px;
  }
  .service2-single-service .fa {
    font-size: 18px;
    height: 45px;
    line-height: 45px;
    width: 45px;
  }
  .service2-single-service h2 {
    font-size: 15px;
  }
  .service2-single-service p {
    font-size: 11px;
  }
  .images {
    text-align: center;
  }
  .split h2.title {
    font-size: 16px;
    line-height: 24px;
  }
  #other-services .service-content p.lead {
    font-size: 12px;
    line-height: 28px;
    margin-bottom: 7px;
  }
  #other-services .service-content p {
    font-size: 12px;
  }
    .about2-intro-image {
    margin-bottom: 10px;
  }
  #default-tab li.nav-item a.nav-link {
    padding: 5px;
    font-size: 12px;
  }
  .single-testimonial-area h3 {
    font-size: 18px;
    line-height: 25px;
  }
  .single-testimonial-area p {
    font-size: 12px;
  }
  .section {
    padding: 60px 0;
  }
  .page-header-area {
    height: 86px;
  }
  .page-header-content h2 {
    font-size: 28px;
  }
  .cta-content h2 {
    font-size: 20px;
    line-height: 32px;
  }
  .cta-content p {
    font-size: 12px;
    line-height: 18px;
    padding-top: 5px;
  }
  .cta-content .btn {
    font-size: 12px;
    margin-top: 0;
    padding: 7px 12px;
  }
  #google-map-contact {
    height: 200px;
    padding: 0;
  }
  .section-padding {
    padding: 60px 0;
  }
  .contact-title-text h2 {
    
    font-size: 30px;
  }
  .contact-title-text p {
    font-size: 14px;
  }
  .contact-widget h2 {
    margin-bottom: 10px;
    font-size: 20px;
  }
  .contact-widget address {
    line-height: 35px;
    font-size: 12px;
  }
  .error-section h1 {
    font-size: 4rem;
  }
  .error-section h4 {
    font-size: 16px;
  }
  #round-tab ul li a {
    font-size: 12px;
    padding: 6px !important;
  }
  #vertical-tab .nav-tabs, #vertical-tab .tab-content {
    vertical-align: top;
    display: inherit;
  }
  #vertical-tab ul.nav-tabs li a {
    font-size: 12px;
    padding: 12px 40px;
  }
  #vertical-tab ul.nav-tabs {
    margin-bottom: 20px;
  }
  #vertical-tab p {
    font-size: 13px;
    line-height: 23px !important;
  }
  .shortcode-buttons-area .col-md-3 , .shortcode-pie-skill-area .col-md-6 {
    margin: 30px 0;
  }
  .single-portfolio-area .small-title {
    margin-top: 30px;
  }
  .portfolio-controls ul li a.btn {
    padding: 5px 30px;
  }
  .featured-wrapper img {
    height: 200px;
  }
  .blog-post-footer ul li a {
    padding: 4px 12px;
  }
  .blog-post-footer a.btn {
    margin-top: 10px;
  }
  iframe {
    height: 200px !important;
  }
  ul.pagination a.page-link {
    font-size: 10px;
    padding: 5px;
  }
  .search-bar {
    margin-top: 40px;
  }
  .sidebar-area .widget {
    margin-bottom: 30px;
  }
  .about-author-section .media-left {
    display: block !important;
    text-align: center;
  }
  .about-author-section .media .media-body {
    padding-left: 10px;
    text-align: center;
    padding-top: 20px;
  }
  .author-footer .fa {
    font-size: 18px !important;
    height: 35px !important;
    line-height: 35px !important;
    width: 35px !important;
  }
  .comments-area .media-left, .comments-area .media-right {
    display: block;
    margin-bottom: 5px;
    text-align: center;
  }
  .comment-author {
    margin: 10px 0;
  }
  .commentor-info > p {
    font-size: 13px;
    line-height: 23px;
  }
}
